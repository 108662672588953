import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ChevronRight } from 'react-feather'
import { Modal } from 'components/pages/contact-us/modal'
import { RegisterNowForm } from 'components/pages/register-now/form'

const RegisterNowPage = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <main className="flex flex-col justify-center items-center -mt-28 sm:-mt-36 relative">
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} />

      <section className="relative w-full mx-auto flex px-6 pt-36 pb-16 lg:pt-60 lg:pb-24  overflow-hidden">
        {/* BACKGROUND IMAGE */}
        <div className="w-full left-0 top-0 bg-cover bg-register-now absolute h-full lg:h-[50%] z-[-1]"></div>

        <div className="flex w-full flex-col gap-16 max-w-screen-2xl mx-auto lg:px-36">
          <div className="flex items-center gap-2 text-white">
            <Link to="/">Home</Link>
            <ChevronRight className="shrink-0" size={16} />
            <Link to="/register-now/" className="font-semibold text-theme-blue">
              Register
            </Link>
          </div>
          <div className="text-4xl lg:text-5xl text-white">
            {t("material-startup-page.register-form.heading")}
          </div>
          <div className="my-12">
            <RegisterNowForm setModalOpen={setModalOpen} />
          </div>
        </div>
      </section>
    </main>
  );
}

export default RegisterNowPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
